const CampaignEdit = () => import('@/modules/campaigns/views/CampaignEdit.vue')
const CampaignIndex = () => import('@/modules/campaigns/views/CampaignIndex.vue')
const CampaignShow = () => import('@/modules/campaigns/views/CampaignShow.vue')
const CampaignLineEdit = () => import('@/modules/campaigns/views/programatic/CampaignLineEdit.vue')
const CampaignLineShow = () => import('@/modules/campaigns/views/programatic/CampaignLineShow.vue')
const CampaignPackageEdit = () => import('@/modules/campaigns/views/programatic/CampaignPackageEdit.vue')
const CampaignPackageShow = () => import('@/modules/campaigns/views/programatic/CampaignPackageShow.vue')

export default [
  {
    path: '/campaigns/programatic/create',
    name: 'campaigns.programatic.create',
    component: CampaignEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/native/create',
    name: 'campaigns.native.create',
    component: CampaignEdit,
    meta: { requiresAuthentication: true }
  },
  // {
  //   path: '/campaigns/search/create',
  //   name: 'campaigns.search.create',
  //   component: CampaignEdit,
  //   meta: { requiresAuthentication: true }
  // },
  {
    path: '/campaigns/create',
    name: 'campaigns.create',
    component: CampaignEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns',
    name: 'campaigns.index',
    component: CampaignIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:id',
    name: 'campaigns.show',
    component: CampaignShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:id/edit',
    name: 'campaigns.edit',
    component: CampaignEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/packages/create',
    name: 'packages.create',
    component: CampaignPackageEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/packages/:id',
    name: 'packages.show',
    component: CampaignPackageShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/packages/:id/edit',
    name: 'packages.edit',
    component: CampaignPackageEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/packages/:pId/lines/create',
    name: 'lines.create',
    component: CampaignLineEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/lines/:id',
    name: 'lines.show',
    component: CampaignLineShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/campaigns/:cId/lines/:id/edit',
    name: 'lines.edit',
    component: CampaignLineEdit,
    meta: { requiresAuthentication: true }
  }
]
