const GoogleOAuthRedirectShow = () => import('@/modules/integrations/views/GoogleOAuthRedirectShow.vue')

export default [
  {
    path: '/google-oauth-redirect',
    name: 'integrations.google-oauth-redirect.show',
    component: GoogleOAuthRedirectShow,
    meta: { requiresAuthentication: true }
  }
]
