<template>
  <div id="main-header">
    <div
      class="font-size-xSmall fw-bold bg-color1 text-center py-2"
      v-if="isImpersonating"
    >
      <p class="mb-0">
        Impersonate habilitado.
        <a href="#" class="text-underline" @click.prevent="stopImpersonate">
          Clique aqui
        </a>
        para encerrar.
      </p>
    </div>
    <template v-if="user">
      <div
        class="font-size-xSmall fw-bold bg-color1 text-center py-2"
        v-if="user.notifications.instability"
      >
        <p class="mb-0">
          Alguns sistemas podem apresentar instabilidade. Qualquer dúvida, entre em contato em <a href="mailto:falecom@pixelroads.com.br">falecom@pixelroads.com.br</a>
        </p>
      </div>
    </template>
    <nav class="">
      <div class="container d-flex justify-content-between">
        <router-link class="logo" :to="'/'">
          <img src="/images/layout/logo-gray-pixel-roads.png" alt="Pixel Roads" />
        </router-link>
        <div class="d-flex align-items-center" v-if="authenticated">
          <div class="d-none d-lg-block">
            <ul class="font-size-small list-unstyled d-flex mb-0 align-items-center">
              <li class="me-5">
                <router-link :to="{ name: 'users.settings.show' }" class="link-color2">Minha Conta</router-link>
              </li>
              <li class="me-5">
                <a v-b-modal="'support-modal'" class="link-color2">
                  <img src="/images/layout/icon-support-black.svg" alt="icon" class="icon-menu me-1" />
                  Dúvidas e Apoio
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click.prevent="logout" class="link-color2">Sair</a>
              </li>
            </ul>
          </div>
          <div class="d-lg-none">
            <button type="button" class="btn-clean btn-toggle actionToggleSidebar" @click="toggleSidebar">
              <i class="fa fa-bars" style="pointer-events: none;"></i>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TheHeader',
  computed: {
    ...mapState('auth', {
      authenticated: state => state.authenticated,
      isImpersonating: state => state.isImpersonating
    }),
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState({
      isSidebarOpen: state => state.isSidebarOpen
    })
  },
  methods: {
    ...mapActions(['setIsSidebarOpen']),
    /**
     * Logout
     */
    async logout () {
      try {
        await AuthService.logout()
        this.$router.push('/')
        location.reload()
      } catch (error) {
      }
    },
    async stopImpersonate () {
      await AuthService.stopImpersonate()
      window.location.href = '/'
    },
    /**
     * Toggle sidebar
     */
    toggleSidebar () {
      this.setIsSidebarOpen(!this.isSidebarOpen)
    }
  }
}
</script>

<style lang="scss" scoped>

#main-header {
  position: relative;
  z-index: 2;

  nav {
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 10px 27px rgba(0, 0, 0, 0.10);
  }

  .logo {
    padding: 20px 35px;
    margin: 0;
    // -webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);
    // box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);

    img {
      width: 130px;
    }
  }

  .icon-menu {
    width: 25px;
  }
}

.btn-author-image {
  width: 50px;
  height: 50px;
  background: #eee;
  display: block;
}

.btn-toggle {
  font-size: 25px;
}
</style>
