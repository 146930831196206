import Vue from 'vue'

/**
 * Format billing profile
 */
Vue.filter('formatBillingProfile', function (profile) {
  switch (profile) {
    case 'ADVERTISER':
      return 'Anunciante'
    case 'COMPANY':
      return 'Empresa'
    default:
      return profile
  }
})

/**
 * Format campaign status
 */
Vue.filter('formatCampaignStatus', function (status) {
  switch (status) {
    case 'ACTIVE':
      return 'Ativo'
    case 'PENDING':
      return 'Pendente'
    case 'FINISHED':
      return 'Encerrado'
    case 'PAUSED':
      return 'Pausado'
    case 'ERROR':
      return 'Erro'
    case 'FAILED`':
      return 'Falha na criação'
    case 'DRAFT':
      return 'Rascunho'
    default:
      return status
  }
})

/**
 * Format campaign status
 */
Vue.filter('formatCampaignType', function (type) {
  switch (type) {
    case 'PROGRAMATIC':
      return 'Programática'
    case 'NATIVE':
      return 'Native'
    default:
      return type
  }
})

/**
 * Format creative format
 */
Vue.filter('formatCreativeFormat', function (format) {
  switch (format) {
    case 'DISPLAY':
      return 'Display'
    case 'NATIVE_DISPLAY':
      return 'Native Display'
    case 'VIDEO':
      return 'Vídeo'
    default:
      return format
  }
})

/**
 * Format credit card brands
 */
Vue.filter('formatCreditCardBrand', function (brand) {
  return brand.toString().toUpperCase()
})

/**
 * Format custom targeting type
 */
Vue.filter('formatCustomTargetingType', function (status) {
  switch (status) {
    case 'BLACKLIST':
      return 'Blocklist'
    case 'EMAIL_AUDIENCE':
      return 'Audiência de email'
    case 'SITE_LIST':
      return 'Lista de sites'
    case 'WISHLIST':
      return 'Wishlist'
    default:
      return status
  }
})

/**
 * Format nfse status
 */
Vue.filter('formatNFSeStatus', function (status) {
  switch (status) {
    case 'ISSUED':
      return 'Emitido'
    case 'ERROR':
      return 'Erro na emissão'
    case 'REJECTED':
      return 'Rejeitado'
    case 'CANCELED':
      return 'Cancelado'
    case 'PENDING':
      return 'Pendente'
    case 'CREATED':
      return 'Criado'
    case 'WAITING':
      return 'Aguardando emissão'
    default:
      return status
  }
})

/**
 * Format objectives
 */
Vue.filter('formatObjective', function (objective) {
  switch (objective) {
    case 'IMPRESSION':
      return 'Impressão'
    case 'CONVERSION':
      return 'Conversão'
    case 'RETARGETING':
      return 'Retargeting'
    default:
      return objective
  }
})

/**
 * Format payment
 */
Vue.filter('formatPaymentMethod', function (method) {
  switch (method) {
    case 'CREDIT_CARD':
      return 'Cartão de Crédito'
    case 'BOLETO':
      return 'Boleto Bancário'
    case 'PIX':
      return 'PIX'
    case 'DEPOSIT':
      return 'Depósito'
    default:
      return method
  }
})

/**
 * Format payment status
 */
Vue.filter('formatPaymentStatus', function (status) {
  switch (status) {
    case 'PAID':
      return 'Pago'
    case 'PENDING':
      return 'Pendente cliente'
    case 'PROCESSING':
      return 'Processando'
    case 'AUTHORIZED':
      return 'Autorizado'
    case 'REFUNDED':
      return 'Estornado'
    case 'WAITING_PAYMENT':
      return 'Aguardando pagamento'
    case 'PENDING_REFUND':
      return 'Aguardando estorno'
    case 'REFUSED':
      return 'Recusado'
    case 'CHARGEDBACK':
      return 'Chargeback'
    case 'EXPIRED':
      return 'Expirado'
    case 'CANCELED':
      return 'Cancelado'
    default:
      return status
  }
})

/**
 * Format status
 */
Vue.filter('formatStatus', function (status) {
  switch (status) {
    case 'ACTIVE':
      return 'Ativo'
    case 'INACTIVE':
      return 'Desativado'
    case 'PENDING':
      return 'Pendente'
    case 'FINISHED':
      return 'Encerrado'
    case 'PAUSED':
      return 'Pausado'
    case 'ERROR':
      return 'Erro'
    case 'FAILED`':
      return 'Falha na criação'
    case 'DRAFT':
      return 'Rascunho'
    default:
      return status
  }
})

/**
 * Format user roles
 */
Vue.filter('formatUserRole', function (role) {
  switch (role) {
    case 'administrator':
      return 'Administrador'
    case 'user-manager':
      return 'Usuário Administrador'
    case 'user':
      return 'Usuário'
  }
})

/**
 * Format wallet status to sign
 */
Vue.filter('formatWalletTypeToSignal', function (status) {
  switch (status) {
    case 'CREDIT':
      return '+'
    case 'FAILED_TRANSFER_FUND':
      return '+'
    case 'DEBIT':
      return '-'
    case 'CHARGEBACK':
      return '-'
    case 'REFUND':
      return '-'
    case 'CHECKOUT':
      return '-'
  }
})
