import Vue from 'vue'

Vue.filter('formatNumber', function (number) {
  return new Intl.NumberFormat('pt-BR').format(number)
})

Vue.filter('formatMoney', function (number) {
  const decSep = ','
  const thouSep = '.'
  const decPlaces = 2
  const sign = number < 0 ? '-' : ''
  const i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  // eslint-disable-next-line no-use-before-define
  let j = (j = i.length) > 3 ? j % 3 : 0

  return 'R$ ' + sign +
      (j ? i.substr(0, j) + '.' : '') +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
      (decPlaces ? decSep + Math.abs(number - i).toFixed(2).slice(2) : '')
})

/**
 * Format filesize
 */
Vue.filter('formatFileSize', function (size) {
  if (size < 1000) {
    return size + ' bytes'
  } else if (size >= 1000 && size < 1000000) {
    return Math.ceil((size / 1000)) + 'KB'
  } else {
    return Math.ceil((size / 1000000)) + 'MB'
  }
})
