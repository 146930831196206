<template>
  <b-modal
    id="support-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h3 class="highlighted-color1">
        <span>{{ headerTitle }}</span>
      </h3>
      <p class="font-size-small mt-3">
        Acesse nosso blog, onde você encontrará diversos<br class="d-none d-md-block"/> conteúdos sobre a plataforma.
        <br/>
        <a href="https://pixelroads.com.br/blog/" target="_blank" class="btn btn-sm btn-color1 py-2 mt-2">
          Clique aqui
        </a>
      </p>
      <p class="font-size-small">
        ou
      </p>
      <p class="font-size-small">
        Converse com o nosso suporte da plataforma.
      </p>
    </div>
    <iframe
      src="https://typebot.co/suporte-pixel-roads"
      style="border: none; width: 100%; height: 600px"
      ></iframe>
  </b-modal>
</template>

<script>

export default {
  name: 'SupportModal',
  props: ['headerTitle']
}
</script>
